// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-about-the-patron-js": () => import("./../../../src/pages/about-us/about-the-patron.js" /* webpackChunkName: "component---src-pages-about-us-about-the-patron-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-work-gallery-js": () => import("./../../../src/pages/our-work/gallery.js" /* webpackChunkName: "component---src-pages-our-work-gallery-js" */),
  "component---src-pages-our-work-index-js": () => import("./../../../src/pages/our-work/index.js" /* webpackChunkName: "component---src-pages-our-work-index-js" */),
  "component---src-pages-our-work-peace-partners-index-js": () => import("./../../../src/pages/our-work/peace-partners/index.js" /* webpackChunkName: "component---src-pages-our-work-peace-partners-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-stay-in-touch-js": () => import("./../../../src/pages/stay-in-touch.js" /* webpackChunkName: "component---src-pages-stay-in-touch-js" */),
  "component---src-templates-donation-announcement-js": () => import("./../../../src/templates/DonationAnnouncement.js" /* webpackChunkName: "component---src-templates-donation-announcement-js" */),
  "component---src-templates-first-level-page-js": () => import("./../../../src/templates/FirstLevelPage.js" /* webpackChunkName: "component---src-templates-first-level-page-js" */),
  "component---src-templates-gallery-js": () => import("./../../../src/templates/Gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/Projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-second-level-page-js": () => import("./../../../src/templates/SecondLevelPage.js" /* webpackChunkName: "component---src-templates-second-level-page-js" */)
}

